@use '@angular/material' as mat;

@include mat.core();

$dark-primary: mat.define-palette(mat.$light-blue-palette);
$dark-accent: mat.define-palette(mat.$light-blue-palette);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);

$light-primary: mat.define-palette(mat.$light-blue-palette);
$light-accent: mat.define-palette(mat.$light-blue-palette);
$light-warn: mat.define-palette(mat.$red-palette);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),

    // Specify the font family for the body and button styles.
    typography:
      mat.define-typography-config(
        $font-family: 'Roboto, sans-serif',
      ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);

@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);

@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
}
