@media screen and (max-width: 1200px) {
  .big-container {
    overflow: auto;
  }

  .minimized {
    min-width: 0;
    margin-left: unset !important;
  }
}

@media screen and (max-width: 992px) {
  .big-container {
    flex-direction: column;
    /* height: calc(100% - 70px) !important; */
    margin-left: unset !important;
  }

  .minimized {
    display: none;
  }

  .panel-notification {
    width: 45vw;
  }
}

@media only screen and (max-width: 1250px) {
  .panel-notification {
    width: 50vw;
  }

  .dynamic-look-up {
    width: 70vw;
    max-width: 70vw !important;
    height: 80%;
  }

  .dynamic-high-look-up {
    width: 70vw;
    max-width: 70vw !important;
    height: 95%;
  }
}

@media only screen and (max-width: 500px) {
  .panel-notification {
    width: 100vw;
  }

  .dynamic-look-up {
    width: 90vw;
    max-width: 90vw !important;
    height: 80%;
  }

  .dynamic-high-look-up {
    width: 90vw;
    max-width: 90vw !important;
    height: 95%;
  }
}
